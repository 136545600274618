import React, { ComponentType, ReactNode } from 'react';
import { getIn } from 'seamless-immutable';
import { withMetrics } from '@catalogo/core-metrics';
import { mergeComponents } from '@catalogo/service-americanas-common/src/helpers/template';
import { useGetPageHome, useGetHomeDefered } from '../hooks/home';

export interface WithPageHomeProps {
  children: ReactNode;
  routeId: string;
  parsedQs: {
    sitepage?: string;
  };
  dispatchMetrics(): void;
}

export const withPageHome = (WrappedComponent: ComponentType) => {
  const EnhancedPageHome = ({ routeId, parsedQs = {}, dispatchMetrics, ...props }: WithPageHomeProps) => {
    const path = '/';
    const sitepage = getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined;

    const { loading, error, data, refetch } = useGetPageHome({
      path,
      routeId,
      sitepage,
      dispatchMetrics,
    });

    const dataDefered = useGetHomeDefered({
      routeId,
      dispatchMetrics,
      data,
    });

    const allData = dataDefered ? mergeComponents(data, dataDefered) : data;
    const publications = getIn(allData, ['page', 'components'], {});

    const componentProps = {
      data: allData,
      error,
      loading,
      refetch,
      publications,
      routeId,
    };

    const EnhancedComponent = WrappedComponent as React.FC;

    return <EnhancedComponent {...componentProps} {...props} />;
  };
  return withMetrics(EnhancedPageHome);
};
