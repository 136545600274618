import { useQuery } from '@apollo/client';
import { getIn } from 'seamless-immutable';
import { getDeferedHome } from '../queries/defer.graphql';
import { getPlacements, mergeComponents } from '../helpers/home';
import pageHomeService from '../queries/home.graphql';

export const useGetPageHome = ({ path, routeId, sitepage, dispatchMetrics }) => {
  const { loading, error, refetch, data } = useQuery(pageHomeService, {
    variables: {
      path,
      routeId,
      sitepage,
    },
    context: routeId,
    onCompleted: /* istanbul ignore next */ data => dispatchMetrics('page:load', data),
  });
  return { loading, error, refetch, data, routeId, dispatchMetrics };
};

export const useGetHomeDefered = ({ data, routeId, dispatchMetrics }) => {
  const template = getIn(data, ['page', 'template'], {});
  const recPlacements = getPlacements('recommendation', template);
  const b2wAdsPlacements = getPlacements('b2wads', template);
  const term = getIn(data, ['page', 'breadcrumb', 0, 'name'], '');

  const { data: dataDefered } = useQuery(getDeferedHome, {
    variables: { recPlacements, b2wAdsPlacements, term, pageType: 'home' },
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1,
    context: routeId,
    ssr: false,
    onCompleted: /* istanbul ignore next */ dataDefered => {
      dispatchMetrics('recommendation:load', mergeComponents(data, dataDefered));
    },
  });
  return dataDefered;
};
